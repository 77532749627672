import {ITkCart} from "../../models/cart";
import {useCallback, useReducer} from "react";
import {Order} from "../../models/order";
import TkOrderReducer, {OrderReducerActionType} from "./reducer";
import {getLastOrdersQuery, makeOrderMutation, makePartnerOrderMutation} from "./queries";
import {httpPostGraphQL} from "../../utils/http-utils";

export interface TkOrderContextType {
    makeOrder: (cart: ITkCart, paymentToken: string, method: string, months: number, totalValueWithFees: number | string | null) => Promise<any>
    order?: Order | null
    orderResult?: any
    getLastOrders: (start?: number, end?: number) => Promise<Order[] | undefined | null>
    percentWithdraw: (order: Order) => number
    makePartnerOrder: (cart: ITkCart) => Promise<any>
}

const TkOrderContext = (): TkOrderContextType => {
    const [state, dispatch] = useReducer(TkOrderReducer, {
        order: {
            totalValue: 0,
        }
    });

    const percentWithdraw = useCallback((order: Order) => {
        const items = order.integrations.flatMap(integration => integration.items)
        const countWithdraw = items.filter(i => i.isWithdraw).length

        return countWithdraw * 100 / items.length
    }, [])

    const makeOrder = useCallback(async (cart: ITkCart, paymentToken: string, method: string, months: number, totalValueWithFees: number | string | null): Promise<Order> => {

        try {
            const {data: result} = await httpPostGraphQL({
                query: makeOrderMutation,
                variables: {cartId: cart._id, paymentToken: paymentToken, method: method, months: months, totalValueWithFees: totalValueWithFees}
            }, {
                timeout: 60000
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {makeOrder}} = result;

                dispatch({
                    type: makeOrder.success ? OrderReducerActionType.make_order : OrderReducerActionType.make_order_error,
                    payload: makeOrder
                });

                return Promise.resolve(makeOrder)
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    const makePartnerOrder = useCallback(async (cart: ITkCart): Promise<Order> => {
        try {
            const {data: result} = await httpPostGraphQL({
                query: makePartnerOrderMutation,
                variables: {cartId: cart._id}
            }, {
                timeout: 60000
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {makePartnerOrder}} = result;

                dispatch({
                    type: makePartnerOrder.success ? OrderReducerActionType.make_order : OrderReducerActionType.make_order_error,
                    payload: makePartnerOrder
                });

                return Promise.resolve(makePartnerOrder)
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, [])

    const getLastOrders = useCallback(async (start?: number, end?: number): Promise<Order[] | undefined | null> => {
        try {
            const {data: result} = await httpPostGraphQL({
                query: getLastOrdersQuery,
                variables: {start, end}
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {getOrders}} = result;
                return Promise.resolve(getOrders)
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);


    return {
        order: state.order,
        makeOrder,
        orderResult: {},
        getLastOrders,
        percentWithdraw,
        makePartnerOrder
    };
};


export default TkOrderContext
